import { ModuleWithProviders, NgModule, Optional, SkipSelf, Type } from '@angular/core';
import { preventReimport } from '@lacerta/util';
import { COOKIE_CONFIG_TOKEN, LacertaCookieConfig } from './cookie.model';
import { LacertaCookieService } from './cookie.service';
import { COOKIE_CONTENT_TOKEN, LacertaCookieContentService } from './cookie-consent.service';

@NgModule({})
export class LacertaCookieModule {
  constructor(@Optional() @SkipSelf() parentModule: LacertaCookieModule, private readonly cookieService: LacertaCookieService) {
    preventReimport(parentModule, 'LacertaCookieModule');
    this.cookieService.initialize();
  }

  static forRoot(
    config: LacertaCookieConfig,
    lacertaCookieContentService: Type<LacertaCookieContentService>
  ): ModuleWithProviders<LacertaCookieModule> {
    return {
      ngModule: LacertaCookieModule,
      providers: [
        { provide: COOKIE_CONFIG_TOKEN, useValue: config },
        { provide: COOKIE_CONTENT_TOKEN, useClass: lacertaCookieContentService },
      ],
    };
  }
}
