import { Directive, Input, OnInit } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[translate]',
})
export class LacertaTranslationDirective extends TranslocoDirective implements OnInit {
  @Input('translate') key!: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('translateRead') inlineRead: string | undefined;
}
