import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RiffYogaAppComponent } from './app.component';
import { RiffYogaFooterModule } from './footer/footer.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RiffYogaCoreModule } from './core/core.module';
import { RiffYogaHeaderModule } from './header/header.module';
import { RouterModule } from '@angular/router';
import { RiffYogaMenuModule } from './menu/menu.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const riffyogaModules = [RiffYogaCoreModule, RiffYogaFooterModule, RiffYogaHeaderModule, RiffYogaMenuModule];

@NgModule({
  bootstrap: [RiffYogaAppComponent],
  declarations: [RiffYogaAppComponent],
  imports: [BrowserModule, BrowserAnimationsModule, riffyogaModules, FlexLayoutModule, RouterModule],
})
export class AppModule {}
