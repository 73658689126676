import { InjectionToken } from '@angular/core';

export const COOKIE_CONFIG_TOKEN = new InjectionToken<LacertaCookieConfig>('lacertaCookieConfig');

export interface LacertaCookieContentText {
  message: string;
  confirm: string;
  deny: string;
  link: string;
}

export interface LacertaCookieConfig {
  theming?: {
    popup?: {
      background?: string;
      text?: string;
      link?: string;
    };
    button?: {
      background?: string;
      text?: string;
    };
  };
  expiration?: number;
}
