import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'lacerta-email',
  template: ` <a [href]="mailHref">{{ text || mailAddress }}</a> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LacertaEmailComponent {
  @Input()
  mailAddress?: string;
  @Input()
  text?: string;

  get mailHref() {
    return `mailto:${this.mailAddress}`;
  }
}
