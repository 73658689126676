import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WagtailMenuPage } from '@lacerta/wagtail';
import { LacertaRoutePaths } from '@lacerta/util';
import { RiffYogaHeaderTheme } from '../wagtail/page.model';

@Component({
  selector: 'riffyoga-header',
  template: `
    <header>
      <div class="content" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5.5rem">
        <a [routerLink]="lacertaRoutePaths.home | slugRoute">
          <div class="logo {{ theme }}" inlineSVG="../../assets/images/logo_riff_yoga_red.svg"></div>
        </a>
        <nav
          fxHide.lt-lg
          fxLayout="row"
          fxLayoutAlign="space-around center"
          fxLayoutGap="var(--space-2)"
          fxLayoutGap.lt-lg="var(--space-1)"
        >
          <ng-container *ngFor="let item of menuPages">
            <a class="{{ theme }} menu-item" [routerLink]="item.page.meta.slug | slugRoute" routerLinkActive="is-active">{{
              item.page.title
            }}</a>
          </ng-container>
        </nav>
      </div>
    </header>
  `,
  styleUrls: ['header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiffYogaHeaderComponent {
  lacertaRoutePaths = LacertaRoutePaths;

  @Input()
  menuPages?: WagtailMenuPage[] | null;

  @Input()
  theme: RiffYogaHeaderTheme = 'dark';
}
