import { Component, Input } from '@angular/core';
import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'lacerta-social-link',
  template: `
    <a [href]="link" target="_blank">
      <fa-stack>
        <fa-icon [icon]="faCircle" stackItemSize="2x"></fa-icon>
        <fa-icon *ngIf="icon" [icon]="icon" stackItemSize="1x" [ngStyle]="{ color: color }"></fa-icon>
      </fa-stack>
    </a>
  `,
})
export class LacertaSocialLinkComponent {
  faCircle = faCircle;

  @Input()
  link?: string;
  @Input()
  icon?: IconDefinition;
  @Input()
  color?: string;
}
