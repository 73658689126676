import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HamburgerModule } from '@lacerta/ui';
import { LacertaRouterModule } from '@lacerta/cms';
import { RiffYogaColComponent } from './menu.column.component';
import { RiffYogaMenuItemComponent } from './menu-item.component';

@NgModule({
  declarations: [MenuComponent, RiffYogaColComponent, RiffYogaMenuItemComponent],
  exports: [MenuComponent],
  imports: [CommonModule, FlexLayoutModule, HamburgerModule, LacertaRouterModule],
})
export class RiffYogaMenuModule {}
