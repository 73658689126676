import { Injectable } from '@angular/core';
import { LacertaTranslationService } from '@lacerta/i18n';
import { LacertaCookieContentService, LacertaCookieContentText } from '@lacerta/shared/cookie-consent';
import { map, switchMap, switchMapTo, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LacertaCmsNgrxFacade } from '@lacerta/cms';
import { cast, filterNullOrUndefined } from '@lacerta/util';
import { RiffYogaHomePage } from '../wagtail/page.model';

@Injectable()
export class RiffYogaCookieConsentService implements LacertaCookieContentService {
  contentText$: Observable<LacertaCookieContentText> = this.lacertaCmsNgrxFacade.availableLanguages$.pipe(
    switchMapTo(
      this.translationService.selectTranslateObject({
        'cookies.message': null,
        'cookies.confirm': null,
        'cookies.deny': null,
        'cookies.link': null,
      })
    ),
    map(([message, confirm, deny, link]) => ({ message, confirm, deny, link }))
  );

  learnMoreLink$: Observable<string> = this.lacertaCmsNgrxFacade.homePage$.pipe(
    filterNullOrUndefined(),
    cast<RiffYogaHomePage>(),
    tap((page) => this.lacertaCmsNgrxFacade.loadPageById(page.cookie_consent.id)),
    switchMap((page) => this.lacertaCmsNgrxFacade.selectDetailPageById(page.cookie_consent.id).pipe(filterNullOrUndefined())),
    map((cookiesPage) => cookiesPage.meta.slug)
  );

  constructor(private readonly translationService: LacertaTranslationService, public lacertaCmsNgrxFacade: LacertaCmsNgrxFacade) {}
}
