import { ChangeDetectionStrategy, Component } from '@angular/core';
import { WagtailMenuPage } from '@lacerta/wagtail';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { buildDummyMenuPage, LacertaCmsNgrxFacade } from '@lacerta/cms';
import { RiffYogaHeaderTheme, RiffYogaHomePage, RiffYogaStreamPage } from './wagtail/page.model';
import { cast, filterNullOrUndefined } from '@lacerta/util';
import { RiffYogaFooterSlugs } from './footer/footer.component';

@Component({
  selector: 'riffyoga-root',
  template: `
    <div fxFlexFill fxLayout="column">
      <ng-container *ngIf="menuTheme$ | async as theme">
        <riffyoga-header [menuPages]="lacertaCmsNgrxFacade.menu$ | async" [theme]="theme"></riffyoga-header>
        <riffyoga-menu
          [menuPages]="menuPagesWithHome$ | async"
          [availableLanguages]="lacertaCmsNgrxFacade.availableLanguages$ | async"
          (switchLanguage)="lacertaCmsNgrxFacade.switchLanguage($event)"
          [theme]="theme"
        ></riffyoga-menu>
      </ng-container>
      <main fxFlex><router-outlet></router-outlet></main>
      <riffyoga-footer [footerLinks]="(footerLinks$ | async) || undefined"></riffyoga-footer>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiffYogaAppComponent {
  menuPagesWithHome$: Observable<WagtailMenuPage[]> = combineLatest([
    this.lacertaCmsNgrxFacade.homePageUrl$,
    this.lacertaCmsNgrxFacade.menu$,
  ]).pipe(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    filter(([_, menu]) => !!menu),
    map(([homePageUrl, menu]) => [buildDummyMenuPage('Home', homePageUrl), ...(menu as WagtailMenuPage[])])
  );
  menuTheme$ = this.lacertaCmsNgrxFacade.currentDetailPage$.pipe(
    map((page) => (page as RiffYogaStreamPage).menu_text_color ?? 'dark'),
    startWith('dark'),
    cast<RiffYogaHeaderTheme>()
  );

  footerLinks$: Observable<RiffYogaFooterSlugs> = this.lacertaCmsNgrxFacade.homePage$.pipe(
    filterNullOrUndefined(),
    cast<RiffYogaHomePage>(),
    tap((page) => this.lacertaCmsNgrxFacade.loadPageById(page.disclaimer.id)),
    tap((page) => this.lacertaCmsNgrxFacade.loadPageById(page.privacy_statement.id)),
    switchMap((page) =>
      combineLatest([this.selectDetailPageFilterNull(page.disclaimer.id), this.selectDetailPageFilterNull(page.privacy_statement.id)])
    ),
    map(([disclaimerPage, privacyStatementPage]) => ({
      privacy: privacyStatementPage.meta.slug,
      disclaimer: disclaimerPage.meta.slug,
    }))
  );

  constructor(public lacertaCmsNgrxFacade: LacertaCmsNgrxFacade) {}

  selectDetailPageFilterNull = (id: number) => this.lacertaCmsNgrxFacade.selectDetailPageById(id).pipe(filterNullOrUndefined());
}
