import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

interface PaginationAdapterConfig<TItems> {
  limit: number;
  loadItems: (offset: number, limit: number) => void;
  totalCount$: Observable<number>;
  selectData$: Observable<TItems[]>;
}

export class LacertaPaginationAdapter<TItems> {
  private offset$ = new BehaviorSubject(0);
  private limit$: BehaviorSubject<number>;

  public hasMore$ = new BehaviorSubject(true);
  public data$: Observable<TItems[]>;
  public totalCount$ = new BehaviorSubject(0);
  public loading$ = new BehaviorSubject(false);

  constructor(config: PaginationAdapterConfig<TItems>) {
    this.limit$ = new BehaviorSubject(config.limit);
    this.data$ = combineLatest([this.offset$, this.limit$]).pipe(
      tap(() => this.loading$.next(true)),
      tap(([offset, limit]) => config.loadItems(offset, limit)),
      switchMap(([offset, limit]) =>
        combineLatest([config.selectData$, config.totalCount$]).pipe(map(([items, totalCount]) => ({ items, offset, limit, totalCount })))
      ),
      tap(({ items, offset, limit, totalCount }) => {
        this.hasMore$.next(totalCount > offset + limit && totalCount > items.length);
        this.totalCount$.next(totalCount);
      }),
      map(({ items }) => items),
      tap(() => this.loading$.next(false))
    );
  }

  loadMore() {
    if (this.loading$.getValue()) {
      return;
    }
    this.offset$.next(this.offset$.getValue() + this.limit$.getValue());
  }
}
