import { Inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { LacertaCookieService } from '@lacerta/shared/cookie-consent';
import { GOOGLE_ANALYTICS_CONFIG_TOKEN, LacertaGoogleAnalyticsConfig } from './google-analytics.config';

export type LacertaGoogleAnalyticsWindow = Window & Record<string, boolean>;
declare let window: LacertaGoogleAnalyticsWindow;

@Injectable({ providedIn: 'root' })
export class LacertaGoogleAnalyticsService {
  constructor(
    private readonly lacertaCookiesService: LacertaCookieService,
    @Inject(GOOGLE_ANALYTICS_CONFIG_TOKEN) public lacertaGoogleAnalyticsConfig: LacertaGoogleAnalyticsConfig
  ) {}

  initialize() {
    this.lacertaCookiesService.cookiesAllowed$.pipe(tap((accepted) => this.handleCookieConsent(accepted))).subscribe();
  }

  private handleCookieConsent(accepted: boolean | undefined) {
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/user-opt-out
    const windowProperty = `ga-disable-${this.lacertaGoogleAnalyticsConfig.trackingCode}`;
    window[windowProperty] = !accepted;
  }
}
