/* eslint-disable @typescript-eslint/naming-convention */
import { ListBlock, WagtailStreamField, WagtailStructBlock } from '@lacerta/wagtail';
import { LacertaImageChooserBlock, LacertaPageChooserBlock, LacertaVideoChooserBlock } from '@lacerta/cms';

export enum RiffYogaWagtailBlockType {
  // BannerBlock
  banner = 'banner',
  // TextBlock
  highlightedText = 'highlighted_text',
  // ListBlock<PageLinkBlock>
  pageLinks = 'page_links',
  // ImageChooserBlock
  photo = 'photo',
  // string
  quote = 'quote',
  // StappenPlanBlock
  stappenplan = 'stappenplan',
  // TextBlock
  text = 'text',
  // VideoBlock
  video = 'video',
  homeLinksPlaceholder = 'home_links_placeholder',
  // PageChooserBlock
  blogOverviewPlaceholder = 'blog_overview_placeholder',
  // number
  agenda = 'agenda',
  pressOverviewPlaceholder = 'press_overview_placeholder',
  // ContactDetailsBlock
  contactDetails = 'contact_details',
  // ContactTextBlock
  contactText = 'contact_text',
  contentStream = 'content_stream',
  title = 'title',
  map = 'map',
}

export interface PageLinkBlock extends WagtailStructBlock {
  image: LacertaImageChooserBlock;
  title: string;
  link: LacertaPageChooserBlock;
  url: string;
}

export interface StapBlock extends WagtailStructBlock {
  title: string;
  subtitle: string;
  text: string;
}

export interface StappenPlanBlock extends WagtailStructBlock {
  title: string;
  stappen: ListBlock<StapBlock>;
}

export interface BannerBlock extends WagtailStructBlock {
  banner_images: ListBlock<WagtailStreamField<LacertaImageChooserBlock>>;
  video: LacertaVideoChooserBlock;
  text: string;
  text_position: 'left' | 'right';
}

export interface TextBlock extends WagtailStructBlock {
  title: string;
  subtitle: string;
  text: string;
  link: LacertaPageChooserBlock;
}

class ContactDetailBlock {
  title?: string;
  text?: string;
}

export type ContactDetailsBlock = ListBlock<ContactDetailBlock>;

export interface ContactTextBlock extends WagtailStructBlock {
  text1: string;
  text2: string;
  text3: string;
}

export type ContentStreamBlock = WagtailStreamField<LacertaImageChooserBlock | LacertaVideoChooserBlock | TextBlock | string>[];
