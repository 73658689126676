import { NgModule } from '@angular/core';
import { RiffYogaHeaderComponent } from './header.component';
import { RiffYogaSharedModule } from '../shared/shared.module';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
  declarations: [RiffYogaHeaderComponent],
  imports: [RiffYogaSharedModule, InlineSVGModule],
  exports: [RiffYogaHeaderComponent],
})
export class RiffYogaHeaderModule {}
