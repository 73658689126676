/* eslint-disable @typescript-eslint/naming-convention */
import {
  LacertaDynamicWagtailPage,
  LacertaImageChooserBlock,
  LacertaImageRenditionField,
  LacertaVideoChooserBlock,
  LacertaWagtailPage,
} from '@lacerta/cms';
import { WagtailStreamField } from '@lacerta/wagtail';

export enum RiffYogaWagtailPageType {
  agenda = 'riffyoga.RiffYogaAgendaPage',
  agendaItem = 'riffyoga.RiffYogaAgendaItemPage',
  blog = 'riffyoga.RiffYogaBlogPage',
  blogItem = 'riffyoga.RiffYogaBlogItemPage',
  contact = 'riffyoga.RiffYogaContactPage',
  home = 'riffyoga.RiffYogaHomePage',
  press = 'riffyoga.RiffYogaPressPage',
  pressItem = 'riffyoga.RiffYogaPressItemPage',
  root = 'riffyoga.RiffYogaRootPage',
  stream = 'riffyoga.RiffYogaStreamPage',
}

export type RiffYogaHeaderTheme = 'light' | 'dark';

export interface RiffYogaStreamPage extends LacertaDynamicWagtailPage {
  hero_video: LacertaVideoChooserBlock;
  hero_images: WagtailStreamField<LacertaImageChooserBlock>[];
  subtitle: string;
  menu_text_color: RiffYogaHeaderTheme;
}

export type RiffYogaHomePage = RiffYogaStreamPage & {
  disclaimer: LacertaWagtailPage;
  privacy_statement: LacertaWagtailPage;
  cookie_consent: LacertaWagtailPage;
};
export type RiffYogaTeamPage = RiffYogaStreamPage;
export type RiffYogaBlogPage = RiffYogaStreamPage;
export type RiffYogaPressPage = RiffYogaStreamPage;
export type RiffYogaContactPage = RiffYogaStreamPage;

export type RiffYogaTeamCategoryPage = LacertaWagtailPage;

export interface RiffYogaTeamMemberPage extends LacertaWagtailPage {
  subtitle: string;
  intro: string;
  image: LacertaImageRenditionField;
  body: string;
}

export interface RiffYogaBlogItemPage extends LacertaWagtailPage {
  image: LacertaImageRenditionField;
  body: string;
  author: string;
}

export interface RiffYogaPressItemPage extends LacertaWagtailPage {
  image: LacertaImageRenditionField;
  body: string;
  link: string;
  source: string;
}

export interface RiffYogaAgendaItemPage extends LacertaWagtailPage {
  date: Date;
  agenda_type: string;
  by: string;
  body: string;
  link: string;
  button_text: string;
}
