import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { GOOGLE_ANALYTICS_CONFIG_TOKEN, LacertaGoogleAnalyticsConfig } from './google-analytics.config';
import {
  IGoogleAnalyticsSettings,
  NGX_GOOGLE_ANALYTICS_INITIALIZER_PROVIDER,
  NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { msInS, preventReimport } from '@lacerta/util';
import { LacertaGoogleAnalyticsService } from './google-analytics.service';

@NgModule({
  imports: [NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule],
})
export class LacertaGoogleAnalyticsModule {
  constructor(
    @Optional() @SkipSelf() parentModule: LacertaGoogleAnalyticsModule,
    private readonly lacertaGoogleAnalyticsService: LacertaGoogleAnalyticsService
  ) {
    preventReimport(parentModule, 'LacertaGoogleAnalyticsModule');
    this.lacertaGoogleAnalyticsService.initialize();
  }

  static forRoot(config: LacertaGoogleAnalyticsConfig): ModuleWithProviders<LacertaGoogleAnalyticsModule> {
    const googleAnalyticsSettings: IGoogleAnalyticsSettings = {
      trackingCode: config.trackingCode,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      initCommands: [{ command: 'config', values: [config.trackingCode, { cookie_expires: config.cookieMaxAge / msInS }] }],
    };

    return {
      ngModule: LacertaGoogleAnalyticsModule,
      providers: [
        {
          provide: NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
          useValue: googleAnalyticsSettings,
        },
        NGX_GOOGLE_ANALYTICS_INITIALIZER_PROVIDER,
        { provide: GOOGLE_ANALYTICS_CONFIG_TOKEN, useValue: config },
      ],
    };
  }
}
