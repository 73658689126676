import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { WagtailMenuPage } from '@lacerta/wagtail';
import { OnChange } from '@lacerta/util';

@Component({
  selector: 'riffyoga-menu-item',
  template: `
    <h3>
      <a
        class="rootPageLink"
        [routerLink]="page?.page?.meta?.slug | slugRoute"
        routerLinkActive="is-active"
        (click)="menuItemClicked.emit()"
        >{{ page?.page?.title }}</a
      >
      <a *ngIf="page?.children?.length" class="subMenuToggle" (click)="menuOpen = !menuOpen">{{ menuOpen ? '-' : '+' }}</a>
    </h3>
    <ng-container *ngIf="page?.children?.length && menuOpen">
      <a
        class="subPageLink"
        [routerLink]="subPage.page.meta.slug | slugRoute"
        *ngFor="let subPage of page?.children"
        routerLinkActive="is-active"
        (click)="menuItemClicked.emit()"
      >
        <h4>{{ subPage.page.title }}</h4>
      </a>
    </ng-container>
  `,
  styleUrls: ['menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiffYogaMenuItemComponent {
  @Input()
  page?: WagtailMenuPage;

  @Input()
  @OnChange('onActiveChange')
  activeSlug?: string | null;

  @Output()
  menuItemClicked = new EventEmitter();

  menuOpen = false;

  onActiveChange(activeSlug: string) {
    const childrenSlugs = this.page?.children?.map((childPage) => childPage.page.meta.slug);
    const slugs = [this.page?.page.meta.slug, ...(childrenSlugs?.length ? childrenSlugs : [])];
    this.menuOpen = slugs.includes(activeSlug);
  }
}
