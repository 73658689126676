import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WagtailMenuPage } from '@lacerta/wagtail';
import { LacertaRoutePaths } from '@lacerta/util';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import * as AppConstants from '../app.constants';

export interface RiffYogaFooterSlugs {
  disclaimer: string;
  privacy: string;
}

@Component({
  selector: 'riffyoga-footer',
  template: `
    <footer *translate="let t; read: 'footer'">
      <section class="footer">
        <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="var(--space-1)" fxLayout.lt-sm="column">
          <div fxFlex="grow" fxLayout="column" fxLayoutGap="var(--space-1)">
            <div>
              <div id="logo" #logo class="logo" inlineSVG="../../../assets/images/logo_riff_yoga_white.svg"></div>
            </div>
          </div>
          <div fxFlex="grow" fxLayout="column" fxLayoutGap="var(--space-1)">
            <div>
              <strong>{{ t('contact') }} Femke</strong>
            </div>
            <div>
              <lacerta-email [mailAddress]="appConstants.email"></lacerta-email>
            </div>
            <div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="var(--space-1)">
              <lacerta-social-link [icon]="icons.instagram" [link]="appConstants.instagramLink" color="var(--red)"></lacerta-social-link>
            </div>
          </div>
          <div fxFlex="grow" fxLayout="column" fxLayoutGap="var(--space-1)">
            <div>
              <address>
                postadres
                <div>{{ appConstants.address.street }}, {{ appConstants.address.postalcode }} {{ appConstants.address.city }}</div>
              </address>
            </div>
            <div>
              <a href="https://mailchi.mp/c6f2476164b1/riffyoga-nieuwsbrief" target="_blank"
                ><strong>{{ t('subscribe') }}</strong></a
              >
            </div>
            <div class="lowercase">{{ t('photography') }} &copy; Johan Martens</div>
          </div>
        </div>
        <small class="disclaimers" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0.5rem">
          <a [routerLink]="footerLinks?.disclaimer | slugRoute">{{ t('termsAndConditions') }}</a>
          <div>&bull;</div>
          <a [routerLink]="footerLinks?.privacy | slugRoute">{{ t('privacyStatement') }}</a>
        </small>
      </section>
    </footer>
  `,
  styleUrls: [`footer.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  @Input()
  menuPages?: WagtailMenuPage[];

  @Input()
  footerLinks?: RiffYogaFooterSlugs;

  appConstants = AppConstants;

  lacertaRoutePaths = LacertaRoutePaths;

  icons = {
    instagram: faInstagram,
  };
}
