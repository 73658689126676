import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { fragmentFromUrl } from './router.selectors';

@Injectable({ providedIn: 'root' })
export class LacertaRouterNgrxFacade {
  fragmentFromUrl$ = this.store$.select(fragmentFromUrl);

  constructor(private store$: Store) {}
}
