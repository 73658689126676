import { LacertaMetaConfig, LacertaMetaField, mapHtmlOnMetaValue, mapImageToMetaValues } from '@lacerta/meta';
import { RiffYogaBlogItemPage, RiffYogaWagtailPageType } from '../wagtail/page.model';

export const blogItemMetaMapping = (page: RiffYogaBlogItemPage) => ({
  [LacertaMetaField.description]: mapHtmlOnMetaValue(page.body),
  ...mapImageToMetaValues(page.image.url, page.image.width, page.image.height),
});

export const metaConfig: LacertaMetaConfig<RiffYogaBlogItemPage> = {
  customPageMetaMappings: [[RiffYogaWagtailPageType.blogItem, blogItemMetaMapping]],
};
