import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Injector,
  Optional,
} from '@angular/core';
import { LacertaCookieConsentComponent } from './cookie-consent.component';
import { COOKIE_CONFIG_TOKEN, LacertaCookieConfig } from './cookie.model';
import { yearInMs } from '@lacerta/util';
import { filter, pairwise, tap } from 'rxjs/operators';
import { LacertaRouterNgrxFacade } from '@lacerta/ngrx';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

const cookiesEnabledFlag = 'cookies-enabled' as const;
const cookiesTimestamp = 'cookies-timestamp' as const;
const resetCookiesFragment = 'resetCookies' as const;

const defaultExpiration = 1 * yearInMs;

const clearAllCookies = () => {
  document.cookie.split(';').forEach((cookie) => {
    document.cookie = cookie.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
  });
};

@Injectable({ providedIn: 'root' })
export class LacertaCookieService {
  private componentRef?: ComponentRef<LacertaCookieConsentComponent>;

  private readonly cookiesAllowedSubject$ = new BehaviorSubject<boolean | undefined>(undefined);
  cookiesAllowed$ = this.cookiesAllowedSubject$.asObservable();

  constructor(
    private factoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private routerFacade: LacertaRouterNgrxFacade,
    private router: Router,
    @Optional() @Inject(COOKIE_CONFIG_TOKEN) public lacertaCookieConfig?: LacertaCookieConfig
  ) {
    this.routerFacade.fragmentFromUrl$
      .pipe(
        filter((fragment) => fragment === resetCookiesFragment),
        tap(() => this.cookiesAllowedSubject$.next(undefined)),
        tap(() => this.router.navigate([], { fragment: undefined }))
      )
      .subscribe();
    this.cookiesAllowed$
      .pipe(
        pairwise(),
        tap(([previous, current]) => this.handleCookiesAllowedChange(previous, current))
      )
      .subscribe();
  }

  initialize() {
    this.cleanExpiredValues();
    const allowedBefore = localStorage.getItem(cookiesEnabledFlag);
    this.cookiesAllowedSubject$.next(allowedBefore === null ? undefined : !!allowedBefore);
  }

  denyCookies() {
    this.cookiesAllowedSubject$.next(false);
  }

  acceptCookies() {
    this.cookiesAllowedSubject$.next(true);
  }

  private cleanExpiredValues() {
    const previousValue = localStorage.getItem(cookiesTimestamp);
    const currentValue = new Date().getTime();
    const isExpired = previousValue && currentValue - +previousValue > this.expiration;
    if (isExpired) {
      localStorage.clear();
    }
  }

  get expiration(): number {
    return this.lacertaCookieConfig?.expiration ?? defaultExpiration;
  }

  private handleCookiesAllowedChange(previous: boolean | undefined, current: boolean | undefined) {
    if (previous === undefined && current === undefined) {
      this.displayCookieConsent();
    } else if (previous !== undefined && current === undefined) {
      this.resetCookieConsent();
    } else if (previous === undefined && current !== undefined) {
      this.toggleCookiesEnabled(current);
    }
  }

  private displayCookieConsent = async () => {
    if (this.componentRef) {
      this.hideCookieConsent();
    }
    const cookieComponentModule = await import('./cookie-consent.component');
    this.componentRef = this.factoryResolver
      .resolveComponentFactory(cookieComponentModule.LacertaCookieConsentComponent)
      .create(this.injector);
    this.appRef.attachView(this.componentRef.hostView);
    const domElem = (this.componentRef.hostView as EmbeddedViewRef<unknown>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
  };

  private resetCookieConsent = () => {
    localStorage.clear();
    clearAllCookies();
    this.cookiesAllowedSubject$.next(undefined);
  };

  private toggleCookiesEnabled(enabled: boolean) {
    this.hideCookieConsent();
    localStorage.setItem(cookiesEnabledFlag, `${enabled}`);
    localStorage.setItem(cookiesTimestamp, `${new Date().getTime()}`);
    if (!enabled) {
      clearAllCookies();
    }
  }

  private hideCookieConsent = () => {
    if (this.componentRef) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
    }
  };
}
