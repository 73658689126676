import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LacertaCarouselComponent } from './carousel.component';
import { LacertaCarouselItemComponent } from './carousel-item.component';
import { LacertaImageModule } from '../image';

@NgModule({
  declarations: [LacertaCarouselComponent, LacertaCarouselItemComponent],
  exports: [LacertaCarouselComponent, LacertaCarouselItemComponent],
  imports: [CommonModule, LacertaImageModule],
})
export class LacertaCarouselModule {}
