import { NgModule } from '@angular/core';
import { FooterComponent } from './footer.component';
import { LacertaEmailModule, LacertaPhoneModule, LacertaSocialLinkModule } from '@lacerta/ui';
import { RiffYogaSharedModule } from '../shared/shared.module';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
  declarations: [FooterComponent],
  imports: [RiffYogaSharedModule, LacertaPhoneModule, LacertaEmailModule, LacertaSocialLinkModule, InlineSVGModule],
  exports: [FooterComponent],
})
export class RiffYogaFooterModule {}
