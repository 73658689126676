import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LacertaPhoneNumberPipe } from './phone-number.pipe';
import { LacertaPhoneNumberComponent } from './phone-number.component';

@NgModule({
  declarations: [LacertaPhoneNumberPipe, LacertaPhoneNumberComponent],
  imports: [CommonModule],
  exports: [LacertaPhoneNumberPipe, LacertaPhoneNumberComponent],
})
export class LacertaPhoneModule {}
