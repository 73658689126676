import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'riffyoga-col',
  styles: [
    `
      :host {
        transition-property: flex, max-width, min-width, background-color;
        transition-duration: 300ms;
        display: block;
      }
    `,
  ],
  template: ` <ng-content></ng-content> `,
})
export class RiffYogaColComponent {
  @Input()
  @HostBinding('style.width')
  fxFlex?: string;
}
