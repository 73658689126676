import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { WagtailMenuPage } from '@lacerta/wagtail';
import { LacertaCmsNgrxFacade } from '@lacerta/cms';
import { RiffYogaHeaderTheme } from '../wagtail/page.model';

export const GUTTER_WIDTH = 'var(--gutter-width)';

@Component({
  selector: 'riffyoga-menu',
  template: `
    <div fxLayout="row" fxFlexFill class="container">
      <riffyoga-col [fxFlex]="gutterWidth" [fxFlex.lt-lg]="menuOpen ? 0 : gutterWidth"></riffyoga-col>
      <div fxFlex fxHide.lt-lg></div>
      <div fxFlex fxHide.lt-lg></div>
      <div fxFlex [fxHide.xs]="menuOpen"></div>
      <riffyoga-col
        [fxFlex]="menuOpen ? '80' : gutterWidth"
        [fxFlex.lt-lg]="menuOpen ? 100 : gutterWidth"
        [style.background-color]="menuOpen ? 'var(--white)' : 'unset'"
      >
        <div class="sidebar" fxLayout="column" fxLayoutGap="1rem" fxLayoutAlign="start center">
          <lacerta-hamburger *ngIf="menuPages" fxFlexAlign="end" [(menuOpen)]="menuOpen" [class]="theme"></lacerta-hamburger>
          <div fxLayout="column" class="language" [class]="theme">
            <a *ngFor="let language of availableLanguages" (click)="switchLanguage.emit(language)" lang="{{ language }}">{{
              language | uppercase
            }}</a>
          </div>
        </div>
      </riffyoga-col>
    </div>
    <div class="menu" [ngClass]="{ open: menuOpen }" fxLayout="row" fxLayoutAlign.xs="center">
      <ul>
        <li *ngFor="let item of menuPages">
          <riffyoga-menu-item
            [page]="item"
            (menuItemClicked)="menuOpen = !menuOpen"
            [activeSlug]="lacertaCmsNgrxFacade.currentSlug$ | async"
          ></riffyoga-menu-item>
        </li>
      </ul>
    </div>
  `,
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
  @Input()
  menuPages?: WagtailMenuPage[] | null;
  @Input()
  availableLanguages?: string[] | null;
  @Output()
  switchLanguage = new EventEmitter<string>();
  @Input()
  theme: RiffYogaHeaderTheme = 'dark';

  menuOpen = false;

  gutterWidth = GUTTER_WIDTH;

  constructor(public lacertaCmsNgrxFacade: LacertaCmsNgrxFacade) {}
}
