import { NgModule } from '@angular/core';
import { LacertaCoreModule } from '@lacerta/core';
import { environment } from '../../environments/environment';
import { RiffYogaWagtailPageType } from '../wagtail/page.model';
import { RiffYogaWagtailBlockType } from '../wagtail/block.model';
import { LacertaWagtailComponentTypeMapping, pageNotFoundBlockType } from '@lacerta/cms';
import { metaConfig } from './meta-config.model';
import { LacertaCookieModule } from '@lacerta/shared/cookie-consent';
import { yearInMs } from '@lacerta/util';
import { LacertaGoogleAnalyticsModule } from '@lacerta/shared/google-analytics';
import { RiffYogaCookieConsentService } from './cookie-consent.service';
import { from } from 'rxjs';

const wagtailComponentTypeMapping: LacertaWagtailComponentTypeMapping = [
  [
    RiffYogaWagtailPageType.home,
    () => import('../wagtail/page/home-page.component').then((component) => component.RiffYogaHomePageComponent),
  ],
  [
    RiffYogaWagtailPageType.stream,
    () => import('../wagtail/page/stream-page.component').then((component) => component.RiffYogaStreamPageComponent),
  ],
  [
    RiffYogaWagtailPageType.blog,
    () => import('../wagtail/page/stream-page.component').then((component) => component.RiffYogaStreamPageComponent),
  ],
  [
    RiffYogaWagtailPageType.blogItem,
    () => import('../wagtail/page/blog-item-page.component').then((component) => component.RiffYogaBlogItemPageComponent),
  ],
  [
    RiffYogaWagtailPageType.press,
    () => import('../wagtail/page/stream-page.component').then((component) => component.RiffYogaStreamPageComponent),
  ],
  [
    RiffYogaWagtailPageType.contact,
    () => import('../wagtail/page/stream-page.component').then((component) => component.RiffYogaStreamPageComponent),
  ],
  [
    RiffYogaWagtailBlockType.pageLinks,
    () => import('../wagtail/block/page-links-block.component').then((component) => component.RiffYogaPageLinksBlockComponent),
  ],
  [
    RiffYogaWagtailBlockType.banner,
    () => import('../wagtail/block/banner.component').then((component) => component.RiffYogaBannerComponent),
  ],
  [
    RiffYogaWagtailBlockType.stappenplan,
    () => import('../wagtail/block/stappenplan-block.component').then((component) => component.RiffYogaStappenplanBlockComponent),
  ],
  [
    RiffYogaWagtailBlockType.contentStream,
    () => import('../wagtail/block/content-stream-block.component').then((component) => component.RiffYogaContentStreamBlockComponent),
  ],
  [
    RiffYogaWagtailBlockType.text,
    () => import('../wagtail/block/text-block.component').then((component) => component.RiffYogaTextBlockComponent),
  ],
  [
    RiffYogaWagtailBlockType.highlightedText,
    () => import('../wagtail/block/highlighted-text-block.component').then((component) => component.RiffYogaHighlightedTextBlockComponent),
  ],
  [
    RiffYogaWagtailBlockType.photo,
    () => import('../wagtail/block/photo-block.component').then((component) => component.RiffYogaPhotoBlockComponent),
  ],
  [
    RiffYogaWagtailBlockType.quote,
    () => import('../wagtail/block/quote-block.component').then((component) => component.RiffYogaQuoteBlockComponent),
  ],
  [
    RiffYogaWagtailBlockType.contactDetails,
    () => import('../wagtail/block/contact-details-block.component').then((component) => component.RiffYogaContactDetailsBlockComponent),
  ],
  [
    RiffYogaWagtailBlockType.contactText,
    () => import('../wagtail/block/contact-text-block.component').then((component) => component.RiffYogaContactTextBlockComponent),
  ],
  [
    RiffYogaWagtailBlockType.homeLinksPlaceholder,
    () => import('../wagtail/block/home-links-block.component').then((component) => component.RiffYogaHomeLinksBlockComponent),
  ],
  [
    RiffYogaWagtailBlockType.pressOverviewPlaceholder,
    () => import('../wagtail/block/press-overview.component').then((component) => component.RiffYogaPressOverviewComponent),
  ],
  [
    RiffYogaWagtailBlockType.blogOverviewPlaceholder,
    () => import('../wagtail/block/blog-overview.component').then((component) => component.RiffYogaBlogOverviewComponent),
  ],
  [
    RiffYogaWagtailBlockType.agenda,
    () => import('../wagtail/block/agenda-block.component').then((component) => component.RiffYogaAgendaBlockComponent),
  ],
  [
    RiffYogaWagtailBlockType.title,
    () => import('../wagtail/block/title-block.component').then((component) => component.RiffYogaTitleBlockComponent),
  ],
  [RiffYogaWagtailBlockType.map, () => import('../wagtail/block/map.component').then((component) => component.RiffYogaMapBlockComponent)],
  [
    pageNotFoundBlockType,
    () => import('../wagtail/block/page-not-found-block.component').then((value) => value.RiffYogaPageNotFoundBlockComponent),
  ],
];
const lacertaCoreConfig = {
  wagtailComponentTypeMapping,
  metaConfig,
  rootPageType: RiffYogaWagtailPageType.root,
  menuParentPageType: RiffYogaWagtailPageType.home,
  environment,
  defaultLang: 'nl',
  importTranslationFile: (lang: string) => from(import(`../../assets/i18n/${lang}.json`)),
};

@NgModule({
  imports: [
    LacertaCoreModule.forRoot(lacertaCoreConfig),
    LacertaCookieModule.forRoot(
      { theming: { button: { background: 'var(--red)' } }, expiration: 1 * yearInMs },
      RiffYogaCookieConsentService
    ),
    LacertaGoogleAnalyticsModule.forRoot({ trackingCode: environment.googleAnalyticsToken, cookieMaxAge: 1 * yearInMs }),
  ],
})
export class RiffYogaCoreModule {}
