import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LacertaTranslationModule } from '@lacerta/i18n';
import { LacertaRouterModule } from '@lacerta/cms';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  exports: [CommonModule, FlexLayoutModule, LacertaTranslationModule, LacertaRouterModule],
})
export class RiffYogaSharedModule {}
