import { Pipe, PipeTransform } from '@angular/core';
import { WagtailStreamField } from '@lacerta/wagtail';

@Pipe({
  name: 'streamFieldsToValues',
})
export class StreamFieldsToValuesPipe<T> implements PipeTransform {
  transform(streamFields: WagtailStreamField<T>[]): T[] {
    return streamFields?.map((streamField) => streamField.value);
  }
}
