import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { LacertaCookieContentText } from './cookie.model';

export const COOKIE_CONTENT_TOKEN = new InjectionToken<LacertaCookieContentService>('lacertaCookieContent');

export interface LacertaCookieContentService {
  contentText$: Observable<LacertaCookieContentText>;
  learnMoreLink$: Observable<string>;
}
